import React from 'react';

class Song extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        // console.log(this.props);
    }


    render() {
        const multiplesInDiv = (
            <div key={Math.random(50000)} className='card song'>
                <div key={Math.random(50000)} className='card-body'>
                    <div key={Math.random(50000)} className='row'>
                        <div key={Math.random(50000)} className='col' onClick={() => this.props.moveSongUp(this.props.songInt)}>
                            <i key={Math.random(50000)} className="fa-solid fa-chevron-up"></i>
                        </div>
                    </div>
                    <hr />
                    <div key={Math.random(50000)} className='row'>
                        <div key={Math.random(50000)} className='col'>
                            <p key={Math.random(50000)}>{this.props.song}</p>
                        </div>
                        <div key={Math.random(50000)} className='col'>
                            <p key={Math.random(50000)}>{this.props.url}</p>
                        </div>
                        <div key={Math.random(500000)} className='col-auto'>
                            <p key={Math.random(500000)}>File Type: {this.props.filetype}</p>
                        </div>
                        <div key={Math.random(500000)} className='col-auto'>
                            <p key={Math.random(500000)}>Completed: {this.props.completed?"Yes":"No"}</p>
                        </div>
                        <div key={Math.random(50000)} className='col-auto'>
                            <i key={Math.random(50000)} className="fa-solid fa-trash" onClick={() => this.props.deleteSong(this.props.songInt)}></i>
                        </div>
                    </div>
                    {/* <div key={Math.random(50000)} className='row'>
                        <div key={Math.random(50000)} className='col'>
                            <input onKeyUp={this.props.updateTiming} value={this.props.timing} />
                        </div>
                    </div> */}
                    <hr />
                    <div key={Math.random(50000)} className='row'>
                        <div key={Math.random(50000)}  className='col' onClick={() => this.props.moveSongDown(this.props.songInt)}>
                            <i key={Math.random(50000)} className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}
  
export default Song;