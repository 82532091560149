import logo from './logo.svg';
import './App.css';
import Song from './song';

import React from 'react';



class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            songs:[],
            viewSongs:[],
            readurl:"https://wedding.burgertechnologies.com/readsongs.php",
            updateurl:"https://wedding.burgertechnologies.com/savesongs.php",
            name:"",
            url:"",
            filetype:"music"
        }
        
    }

    // let readurl = "http://localhost/jukebox/musicmanager/readsongs.php"
    // //let readurl = "https://wedding.burgertechnologies.com/songs.json";
    // let updateurl = "http://localhost/jukebox/musicmanager/savesongs.php" 
    // //let updateurl = "https://wedding.burgertechnologies.com/savesongs.php";

    componentDidMount = () => {
        fetch(this.state.readurl).then(res => res.json()).then(result => {
            this.setState({
                songs:result,
                viewSongs:result,
            })
        }).finally(() => {
            // console.log(this.state.songs)
        })
    }

    deleteSong = (songInt) => {
        let newList = this.state.songs.filter((obj,ind) => {
            return ind !== songInt;
        });
        
        this.setState({
            songs: newList,
            viewSongs: newList
        },() => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    payload:this.state.songs
                }),
            };
        
            fetch(this.state.updateurl,requestOptions).then(res => res.json()).then(result => {
                // console.log(result)
            });
        })
    }

    moveSongDown = (songInt) => {
        if(songInt + 1 < this.state.songs.length)
        {
            let hold = this.state.songs[songInt];
            let newList = [...this.state.songs];
            newList[songInt] = this.state.songs[songInt + 1];
            newList[songInt + 1] = hold; 
            
            this.setState({
                songs: newList,
                viewSongs: newList
            },() => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        payload:this.state.songs
                    }),
                };
            
                fetch(this.state.updateurl,requestOptions).then(res => res.json()).then(result => {
                    // console.log(result)
                });
            })
        }   
    }

    moveSongUp = (songInt) => {
        if(songInt - 1 > -1)
        {
            let hold = this.state.songs[songInt];
            let newList = [...this.state.songs];
            newList[songInt] = this.state.songs[songInt - 1];
            newList[songInt - 1] = hold; 
            
            this.setState({
                songs: newList,
                viewSongs: newList
            },() => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        payload:this.state.songs
                    }),
                };
            
                fetch(this.state.updateurl,requestOptions).then(res => res.json()).then(result => {
                    //console.log(result)
                });
            })
        }
    }

    addSong = () => {
        let name = this.state.name;
        let url = this.state.url;
        let newSongs = [...this.state.songs]
        newSongs.unshift({title:name,url:url,completed:false,filetype:this.state.filetype});
        //console.log("Songs",newSongs)
        this.setState({
            songs: newSongs,
            viewSongs: newSongs,
        },() => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    payload:this.state.songs
                }),
            };
        
            fetch(this.state.updateurl,requestOptions).then(res => res.json()).then(result => {
                //console.log(result)
            }).finally(() => {
                this.setState({
                    name:"",
                    url:""
                })
            });
        })
    }
    
    updateTiming = (e) => {
        let newTiming = e.target.value;
        this.setState({
            timing:newTiming
        })
    }

    filterSongs = () => {
        let filtered = this.state.songs.filter((obj) => {return obj.title.toLowerCase().includes(this.state.name.toLowerCase())})
        
        this.setState({
            viewSongs:filtered,
        })
    }
    filterSongsUrl = () => {
        let filtered = this.state.songs.filter((obj) => {return obj.url.includes(this.state.url)})
        
        this.setState({
            viewSongs:filtered,
        })
    }

    updateFileTypeSelect = (e) => {
        this.setState({
            filetype: e.target.id
        })
    }

    render() {
        return (
            <div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='form-floating ml'>
                            <input id='name' className='form-control' onKeyUp={this.filterSongs} onKeyDown={(event) => { this.setState({name:event.target.value})}} onChange={(event) => { this.setState({name:event.target.value})}} placeholder='Song Name' value={this.state.name} />
                            <label htmlFor='name'>Song Name</label>
                        </div>
                        <br />
                        <div className='form-floating ml btn-group' role="group">
                            <input id='url' className='form-control' onKeyUp={this.filterSongsUrl} onKeyDown={(event) => { this.setState({url:event.target.value})}} onChange={(event) => { this.setState({url:event.target.value})}} placeholder='Song Youtube URL' value={this.state.url} />
                            <label htmlFor='url'>Song Youtube Url</label>
                            <button className='btn btn-primary' type="button" onClick={this.addSong}>Go</button>
                            {/* <button className='btn btn-secondary' onClick={paste}>Paste</button> */}
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='row'>
                            <div className='col'>
                                <input id="music" type='radio' onChange={this.updateFileTypeSelect} checked={this.state.filetype === "music"} />
                                <label htmlFor='music'>Music</label>
                                <input id="video" type='radio' onChange={this.updateFileTypeSelect} checked={this.state.filetype === "video"} />
                                <label htmlFor='video'>Video</label>
                                <input id="playlist" type='radio' onChange={this.updateFileTypeSelect} checked={this.state.filetype === "playlist"} />
                                <label htmlFor='playlist'>Playlist</label>
                            </div>
                            <div className='col-auto'>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                {
                    this.state.viewSongs.map((obj,ind) => {
                        return(<Song
                            key={Math.random(50000)}
                            songInt={ind}
                            song={obj.title} 
                            url={obj.url} 
                            filetype={obj.filetype}
                            completed={obj.completed}
                            deleteSong={this.deleteSong}
                            moveSongUp={this.moveSongUp}
                            moveSongDown={this.moveSongDown}
                            updateTiming={this.updateTiming}
                        ></Song>)
                    })
                }
                </div>
            </div>)
    };
}

export default App;